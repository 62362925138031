.iconHeader {
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 300px;
  height: 99px;
  left: 50%;
  margin-left: -150px;
  text-align: center;

  :global(.header-text) {
    border: 10px solid #FFFFFF;
    filter: drop-shadow(0px 0px 4px rgba(161, 200, 209, 0.75));
    border-radius: 100px;
    background-color: #295dab;
    border-radius: 100px;
    color: #fff;
    display: inline-block;
    padding: 5px 30px;
    margin: 20px 0 0 5px;
  }
}
