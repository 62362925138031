@font-face {
    font-family: 'UTM Avo';
    src: url('assets/fonts/UTMAvo-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/UTMAvo-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Avo';
    src: url('assets/fonts/UTMAvo.woff2') format('woff2'),
        url('assets/fonts/UTMAvo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Avo';
    src: url('assets/fonts/UTMAvoBold.woff2') format('woff2'),
        url('assets/fonts/UTMAvoBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'UTM Avo';
    src: url('assets/fonts/UTMAvo-Italic.woff2') format('woff2'),
        url('assets/fonts/UTMAvo-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

