.wrapper {
  background-image: url('./../../../assets/images/background-hero-sm.jpg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;

  @media only screen and (min-width: 768px) {
    background-image: url('./../../../assets/images/background-hero-lg.jpg');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
